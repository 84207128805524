import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavTop } from "./components/NavTop";
import { Footer } from "./components/Footer";
import BottomNav from "./components/BottomNav";
import { isDesktop } from "react-device-detect";
import Loader from "./components/Loader";
import { ScrollToTop } from "./components/Scrolltotop";

const Home = lazy(() => import("./pages/Home"));
const ServicePage = lazy(() => import("./pages/ServicePage"));
const Work = lazy(() => import("./pages/workSection/Work"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavTop />
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/work" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Suspense>
        <Footer />
        {!isDesktop && <BottomNav />}
      </Router>
    </div>
  );
}

export default App;
