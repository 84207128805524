import Container from "react-bootstrap/Container";
import "../css/bottomnav.css";
import { HiHome } from "react-icons/hi";
import {
  MdOutlineWork,
  MdMiscellaneousServices,
  MdPermContactCalendar,
} from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const BottomNav = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nav = [
    {
      name: "Home",
      path: "/",
      icon: <HiHome size={24} color={activeIndex === 0 ? "#F70B0B" : "inherit"} />,
    },
    {
      name: "Work",
      path: "/work",
      icon: <MdOutlineWork size={24} color={activeIndex === 1 ? "#F70B0B" : "inherit"} />,
    },
    {
      name: "Services",
      path: "/services",
      icon: <MdMiscellaneousServices size={24} color={activeIndex === 2 ? "#F70B0B" : "inherit"} />,
    },
    {
      name: "About",
      path: "/about",
      icon: <RiTeamFill size={24} color={activeIndex === 3 ? "#F70B0B" : "inherit"} />,
    },
    {
      name: "Contact",
      path: "/contact",
      icon: <MdPermContactCalendar size={24} color={activeIndex === 4 ? "#F70B0B" : "inherit"} />,
    },
  ];

  useEffect(() => {
    const currentPath = window.location.pathname;
    const index = nav.findIndex((item) => item.path === currentPath);
    setActiveIndex(index);
  }, []);

  return (
    <Container fluid className="border-top border-light bottom-nav rounded">
      <ul className="d-flex justify-content-around list-unstyled">
        {nav.map((item, index) => (
          <li key={index} className="">
            <Link
              className="text-light text-decoration-none fw-bold"
              to={item.path}
              onClick={() => setActiveIndex(index)}
            >
              <div className="d-flex flex-column align-items-center">
                {item.icon} <span>{item.name}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default BottomNav;
