import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/Images/logo-new.png";
import { isDesktop } from "react-device-detect";
import { NavLink, useLocation } from "react-router-dom";
import "../css/navbar.css";

export const NavTop = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = React.useState("");

  // Set active link based on the current location
  React.useEffect(() => {
    const pathname = location.pathname.toUpperCase();
    setActiveLink(pathname);
  }, [location]);

  const handleNavLinkClick = (linkText: React.SetStateAction<string>) => {
    setActiveLink(linkText);
  };

  return (
    <Navbar
      expand="lg"
      className="bg-transparent fw-bold fs-5 position-absolute"
    >
      <Navbar.Collapse>
        <Nav className="justify-content-around w-100">
          <NavLink
            to="/services"
            className={activeLink === "/SERVICES" ? "active" : "textlight"}
            onClick={() => handleNavLinkClick("/SERVICES")}
          >
            SERVICES
          </NavLink>
          <NavLink
            to="/work"
            className={activeLink === "/WORK" ? "active" : "textlight"}
            onClick={() => handleNavLinkClick("/WORK")}
          >
            WORK
          </NavLink>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Brand as={NavLink} to="/">
        <img
          src={logo}
          width="75"
          height="auto"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-around w-100">
          <NavLink
            to="/about"
            className={activeLink === "/ABOUT" ? "active" : "textlight"}
            onClick={() => handleNavLinkClick("/ABOUT")}
          >
            ABOUT US
          </NavLink>
          <NavLink
            to="/contact"
            className={activeLink === "/CONTACT" ? "active" : "textlight"}
            onClick={() => handleNavLinkClick("/CONTACT")}
          >
            CONTACT US
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
