import React from "react";
import { Container, Button } from "react-bootstrap";
import footerLogo from "../assets/Images/footer-logo.jpg";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowDownRight } from "react-icons/bs";
import "../css/Footer.css";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <Container fluid className="px-5">
      <div className="row footer_content">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div className="footer_area my-5">
            <div className="footer_logo">
              <img
                src={footerLogo}
                alt="footer_logo"
                className="footer_img_logo"
              />
            </div>
            <div className="footer_soical_icons">
              <a
                href="https://www.facebook.com/xovisuals/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className="social" color="#fff" size={35} />
              </a>
              <a
                href="https://www.instagram.com/xo.visuals"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className="social mx-3" color="#fff" size={35} />
              </a>
              <a
                href="https://www.youtube.com/c/XOVisuals"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="social" color="#fff" size={35} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div className="footer_list my-5">
            <ul className="ul_list">
              <li className="li_list">
                <Link to="/services" className="link">
                  our services
                </Link>
              </li>
              <li className="li_list">
                <Link to="/work" className="link">
                  recent work
                </Link>
              </li>
              <li className="li_list">
                <Link to="/about" className="link">
                  About us
                </Link>
              </li>
              <li className="li_list">
                <Link to="/contact" className="link">
                  contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div className="footer_masterpiece my-5">
            <p className="masterpice">
              LET’S <br /> MAKE A <br />
              MASTERPIECE.
            </p>
          </div>
          <Button variant="danger" className="rounded-pill" onClick={() => navigate("/contact")}>
            <h3 className="join_button get_start">
              GET STARTED <BsArrowDownRight />
            </h3>
          </Button>
        </div>
      </div>
      {/* Copyright */}
      <div className="pb-5 pb-md-0 mb-4 mb-md-0">
        <p className="text-center text-light mt-5">
          © 2024 XO Visuals. All rights reserved. Designed by{" "}
          <a
            href="https://www.instagram.com/artisthubhai"
            className="text-decoration-none text-light fw-bold"
          >
            Div
          </a>{" "}
          | Developed by{" "}
          <a
            href="https://cadillacs.in"
            target="_blank"
            className="text-decoration-none text-light fw-bold"
          >
            Cadillacs.in
          </a>
        </p>
      </div>
    </Container>
  );
};
